document.querySelectorAll( '[data-typed]' ).forEach( typedElement => new Typed( typedElement, {
  strings: typedElement.dataset.to.replace( '[', '' ).replace( ']', '' ).replace( /\s/g, '' ).split( ',' ),
  typeSpeed: 90,
  backSpeed: 90,
  startDelay: 500,
  loop: true,
  loopCount: Infinity,
  showCursor: false,
  smartBackspace: false
} ) )
