const Tabs = {}

Tabs.init = () => {

  // Get relevant elements and collections
  const tabbed = document.querySelector( '[data-tabs]' )

  if ( !tabbed ) return false

  const tablist = tabbed.querySelector( 'ul' )
  const tabs = tablist.querySelectorAll( 'a' )
  const panels = tabbed.querySelectorAll( '[data-tab]' )
  // Add the tablist role to the first <ul> in the tabbed container
  tablist.setAttribute( 'role', 'tablist' )
  Tabs.setupTabs( tabs, panels, tablist )
  Tabs.setupSemantics( tabs, panels )
  Tabs.revealFirst( tabs, panels )

  return true
}

// Add semantics are remove user focusability for each tab
Tabs.setupTabs = ( tabs, panels, tablist ) => {
  tabs.forEach( ( tab, i ) => {
    tab.setAttribute( 'role', 'tab' )
    tab.setAttribute( 'id', `tab${i + 1}` )
    tab.setAttribute( 'tabindex', '-1' )
    tab.parentNode.setAttribute( 'role', 'presentation' )

    // Handle clicking of tabs for mouse users
    tab.addEventListener( 'click', e => {
      e.preventDefault()
      const currentTab = tablist.querySelector( '[aria-selected]' )
      if ( e.currentTarget !== currentTab ) {
        Tabs.switchTab( tabs, panels, currentTab, e.currentTarget )
      }
    } )

    // Handle keydown events for keyboard users
    tab.addEventListener( 'keydown', e => {
      // Get the index of the current tab in the tabs node list
      const index = Array.prototype.indexOf.call( tabs, e.currentTarget )
      // Work out which key the user is pressing and
      // Calculate the new tab's index where appropriate
      const dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null
      if ( dir !== null ) {
        e.preventDefault()
        // If the down key is pressed, move focus to the open panel,
        // otherwise switch to the adjacent tab
        dir === 'down' ? panels[i].focus() : tabs[dir] ? Tabs.switchTab( e.currentTarget, tabs[dir] ) : void 0
      }
    } )
  } )
}

Tabs.setupSemantics = ( tabs, panels ) => {
  panels.forEach( ( panel, i ) => {
    panel.setAttribute( 'role', 'tabpanel' )
    panel.setAttribute( 'tabindex', '-1' )
    // const id = panel.getAttribute( 'id' );
    panel.setAttribute( 'aria-labelledby', tabs[i].id )
    panel.hidden = true
  } )
}

Tabs.revealFirst = ( tabs, panels ) => {
  // Initially activate the first tab and reveal the first tab panel
  tabs[0].removeAttribute( 'tabindex' )
  tabs[0].setAttribute( 'aria-selected', 'true' )
  panels[0].hidden = false
}

// The tab switching function
Tabs.switchTab = ( tabs, panels, oldTab, newTab ) => {
  newTab.focus()
  // Make the active tab focusable by the user (Tab key)
  newTab.removeAttribute( 'tabindex' )
  // Set the selected state
  newTab.setAttribute( 'aria-selected', 'true' )
  oldTab.removeAttribute( 'aria-selected' )
  oldTab.setAttribute( 'tabindex', '-1' )
  // Get the indices of the new and old tabs to find the correct
  // tab panels to show and hide
  const index = Array.prototype.indexOf.call( tabs, newTab )
  const oldIndex = Array.prototype.indexOf.call( tabs, oldTab )
  panels[oldIndex].hidden = true
  panels[index].hidden = false
}

document.addEventListener( 'DOMContentLoaded', () => Tabs.init() )

