// Adapted from https://github.com/udacity/ud891/tree/gh-pages/lesson2-focus/07-modals-and-keyboard-traps/solution

// Will hold previously focused element
let focusedElementBeforeMenu

// Find the modal and its overlay
const mobileMenu = document.querySelector( '[data-mobile-menu]' )
const menuOverlay = document.querySelector( '.modal-overlay' )

// Attach listeners to all the modal toggles
const menuToggle = document.querySelector( '[data-open-menu]' )

const trapTabKey = ( e, firstTabStop, lastTabStop ) => {
  // Check for TAB key press
  if ( e.keyCode === 9 ) {

    // SHIFT + TAB
    if ( e.shiftKey ) {
      if ( document.activeElement === firstTabStop ) {
        e.preventDefault()
        lastTabStop.focus()
      }

      // TAB
    } else if ( document.activeElement === lastTabStop ) {
      e.preventDefault()
      firstTabStop.focus()
    }
  }

  // ESCAPE
  if ( e.keyCode === 27 ) {
    closeMenu()
  }
}

/**
 * Opens the modal and traps focus.
 */
const openMenu = () => {

  // Save current focus
  focusedElementBeforeMenu = document.activeElement

  // Listen for and trap the keyboard
  mobileMenu.addEventListener( 'keydown', ( e ) => trapTabKey( e, firstTabStop, lastTabStop ) )

  // Listen for indicators to close the modal
  menuOverlay.addEventListener( 'click', closeMenu )

  // Modal close buttons
  const closeButtons = mobileMenu.querySelectorAll( '[data-close-menu]' )

  // Attach listeners to all the close modal buttons
  for ( let i = 0; i < closeButtons.length; i++ ) closeButtons[i].addEventListener( 'click', closeMenu )

  // Find all focusable children
  const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
  let focusableElements = mobileMenu.querySelectorAll( focusableElementsString )

  // Convert NodeList to Array
  focusableElements = Array.prototype.slice.call( focusableElements )

  const firstTabStop = focusableElements[0]
  const lastTabStop = focusableElements[focusableElements.length - 1]

  menuToggle.setAttribute( 'aria-expanded', 'true' )
  mobileMenu.setAttribute( 'data-menu-closed', 'false' )
  mobileMenu.setAttribute( 'aria-hidden', 'false' )

  // This just allows for the animation, not required.
  setTimeout( () => mobileMenu.classList.remove( 'is-closed' ), 10 )

  // Focus first child
  firstTabStop.focus()

}

menuToggle.addEventListener( 'click', openMenu )

/**
 * Closes the modal.
 */
const closeMenu = () => {
  mobileMenu.classList.add( 'is-closed' )
  menuToggle.setAttribute( 'aria-expanded', 'false' )
  mobileMenu.setAttribute( 'aria-hidden', 'true' )
  mobileMenu.setAttribute( 'data-menu-closed', 'true' )
  focusedElementBeforeMenu.focus()
}
